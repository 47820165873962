<template>
  <div id="favesByCountry">
    <div class="md-layout md-alignment-center-center">
      <div class="md-layout-item md-size-75 md-medium-size-80 md-small-size-100 ">
        <p class="md-headline">{{ $t('faves.favesByCountryTitle') }}</p>
      </div>
      <div class="md-layout-item md-size-75 md-medium-size-80 md-small-size-100">
        <md-card>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-20 md-medium-size-30 md-small-size-50" v-for="(country,index) in countries" v-bind:key="index">
                <router-link target="_blank" :to="{ name: 'favesByCountryName', params: { countryName: country[1] } }" class="link-faves-country" v-if="country[3]">
                  <img :src="country[2]" :alt="country[0]">
                  {{country[0]}}
                </router-link>
                <router-link target="_blank" :to="{ name: 'myFaves', params: { username: country[1] } }" class="link-faves-country" v-else>
                  <img :src="country[2]" :alt="country[1]">
                  {{country[1]}}
                </router-link>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FavesByCountry',
  components: {},
  props: {
    slug: String,
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData;
    },
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    countries: [
      ['us', 'USA', `${process.env.BASE_URL}country-flags/us.png`, null],
      ['UK', 'www.allmyfaves.co.uk', `${process.env.BASE_URL}country-flags/uk.png`, 'favPage'],
      ['it', 'Italy', `${process.env.BASE_URL}country-flags/italy.png`, null],
      ['es', 'Spain', `${process.env.BASE_URL}country-flags/spain.png`, null],
      ['France', 'www.allmyfaves.fr', `${process.env.BASE_URL}country-flags/france.jpg`, 'favPage'],
      ['de', 'Germany', `${process.env.BASE_URL}country-flags/germany.png`, null],
      ['pt', 'Portugal', `${process.env.BASE_URL}country-flags/portugal.png`, null],
      ['eg', 'Egypt', `${process.env.BASE_URL}country-flags/egypt.png`, null],
      ['jp', 'Japan', `${process.env.BASE_URL}country-flags/japan.png`, null],
      ['is', 'Israel', `${process.env.BASE_URL}country-flags/israel.png`, null],
      ['Argentina', 'argentina.allmyfaves.com', `${process.env.BASE_URL}country-flags/argentina.png`, 'favPage'],
      ['Russia', 'www.allmyfaves.com', `${process.env.BASE_URL}country-flags/russia.png`, 'favPage'],
      ['sa', 'saudiarabia', `${process.env.BASE_URL}country-flags/saudiarabia.png`, null],
      ['ph', 'Philippines', `${process.env.BASE_URL}country-flags/philippines.png`, null],
      ['mex', 'Mexico', `${process.env.BASE_URL}country-flags/mexico.png`, null],
      ['ae', 'UAE', `${process.env.BASE_URL}country-flags/uae.png`, null],
      ['ch', 'China', `${process.env.BASE_URL}country-flags/china.png`, null],
      ['ven', 'Venezuela', `${process.env.BASE_URL}country-flags/venezuela.png`, null],
      ['ku', 'kuwait', `${process.env.BASE_URL}country-flags/kuwait.png`, null],
      ['Canada', 'www.allmyfaves.ca', `${process.env.BASE_URL}country-flags/canada.png`, 'favPage'],
      ['Brazil', 'www.allmyfaves.com.br', `${process.env.BASE_URL}country-flags/brazil.png`, 'favPage'],
      ['India', 'www.allmyfaves.co.in', `${process.env.BASE_URL}country-flags/india.png`, 'favPage'],
      ['Australia', 'au.allmyfaves.com', `${process.env.BASE_URL}country-flags/australia.png`, 'favPage'],
      ['si', 'Singapore', `${process.env.BASE_URL}country-flags/singapore.png`, null],
    ],
  }),
  methods: {
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/faveByCountry.scss';
</style>
